<template>
  <div>
    <div class="bg-default-secondary">
      <div class="container py-4">
        <div class="row">
          <div class="col-md-12 section-title">
            <h4 class="text-capitalize title" style="line-height: 38px;">
              <span>feel free to contact us or</span>
              <br />
              <span class="text-primary">visit us personally</span>
            </h4>
            <p>we will response you as fast as possible</p>
          </div>
        </div>
        <div class="row p-3">
          <div class="col-md-5 bg-theam-secondary">
            <div class="contact-detail px-3 py-4">
              <div class="py-4">
                <h4 class="text-center pb-2">
                  <u>Contact Details</u>
                </h4>
                <ul class="text-center">
                  <li>
                    <span>Address: Tinkune, Kathmandu Nepal</span>
                  </li>
                  <li>
                    <span>Phone: 01-4111992 / 9840680875</span>
                  </li>
                  <li>
                    <span>Mail: support@softechpark.com</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-7">
            <div class="inquiei-form p-3 section-title">
              <h4 class="text-center">
                <span>Please Feel free for Inquary,</span>
                <span class="text-primary"> Just drop a line</span>
              </h4>
              <p class="text-center">Our Support team will defitenly reply you, Be Assure!</p>
              <form @submit.prevent="submit" class="row">
                <div class="col-md-6">
                  <div class="mb-2">
                    <input
                      type="text"
                      class="form-control border-radius-0"
                      :class="{ invalid_border: $v.formData.name.$error }"
                      placeholder="Enter your name"
                      @blur="$v.formData.name.$touch()"
                      v-model.trim="formData.name"
                      required
                    />
                    <div class="text-left py-0" v-if="$v.formData.name.$error">
                      <p class="font-xs tx-danger" v-if="!$v.formData.name.required">Name field is required.</p>
                      <p
                        class="font-xs tx-danger"
                        v-if="!$v.formData.name.minLength"
                      >At least 2 character name.</p>
                    </div>
                  </div>
                  <div class="mb-2">
                    <input
                      type="text"
                      class="form-control border-radius-0"
                      placeholder="Enter email address"
                      :class="{ invalid_border: $v.formData.email.$error }"
                      @blur="$v.formData.email.$touch()"
                      v-model.trim="formData.email"
                      required
                    />
                    <div class="text-left py-0" v-if="$v.formData.email.$error">
                      <p
                        class="font-xs tx-danger"
                        v-if="!$v.formData.email.required"
                      >Email field is required.</p>
                      <p
                        class="font-xs tx-danger"
                        v-if="!$v.formData.email.minLength"
                      >At least 6 character email.</p>
                    </div>
                  </div>
                  <div class="mb-2">
                    <input
                      type="text"
                      class="form-control border-radius-0"
                      placeholder="Enter your phone number"
                      :class="{ invalid_border: $v.formData.phone.$error }"
                      @blur="$v.formData.phone.$touch()"
                      v-model.trim="formData.phone"
                      required
                    />
                    <div class="text-left py-0" v-if="$v.formData.phone.$error">
                      <p
                        class="font-xs tx-danger"
                        v-if="!$v.formData.phone.required"
                      >Phone field is required.</p>
                      <p
                        class="font-xs tx-danger"
                        v-if="!$v.formData.phone.minLength"
                      >At least 7 character phone.</p>
                      <p
                        class="font-xs tx-danger"
                        v-if="!$v.formData.phone.maxLength"
                      >Max 15 character phone.</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 text-right">
                  <textarea
                    class="form-control border-radius-0"
                    placeholder="Message"
                    style="height: 100px;"
                    :class="{ invalid_border: $v.formData.message.$error }"
                    @blur="$v.formData.message.$touch()"
                    v-model.trim="formData.message"
                    required
                  ></textarea>
                  <div class="text-left py-0" v-if="$v.formData.message.$error">
                    <span
                      class="font-xs tx-danger"
                      v-if="!$v.formData.message.required"
                    >Message field is required.</span>
                    <p
                      class="font-xs tx-danger"
                      v-if="!$v.formData.message.minLength"
                    >At least 50 character message.</p>
                    <p
                      class="font-xs tx-danger"
                      v-if="!$v.formData.message.maxLength"
                    >Max 500 character message.</p>
                  </div>
                  <div class="text-right mt-3">
                    <button type="submit" class="btn btn-sm btn-primary">Submit</button>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="location-map">
      <div class="py-2">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.9809993171352!2d85.34399461528646!3d27.686982132993023!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1988cd528363%3A0x82ef39f982517662!2sSoftechpark!5e0!3m2!1sen!2snp!4v1597914703463!5m2!1sen!2snp"
          style="width: 100%;height:450px"
          frameborder="0"
          allowfullscreen
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {
  maxLength,
  minLength,
  numeric,
  required,
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      formData: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
    };
  },
  validations: {
    formData: {
      name: { required, minLength: minLength(2), maxLength: maxLength(255) },
      email: { required, minLength: minLength(6), maxLength: maxLength(50) },
      phone: {
        required,
        numeric,
        minLength: minLength(7),
        maxLength: maxLength(15),
      },
      message: {
        required,
        minLength: minLength(50),
        maxLength: maxLength(500),
      },
    },
  },
  methods: {
    submit() {
      if (!this.$v.formData.$invalid) {
        console.log(this.formData);
        // this.$store.commit("setApiUrl", "api/plan/store");
        // this.$store.commit("addData", this.formData);
      } else {
        this.$store.commit("setEventMessage", `Please fill required fields.`);
      }
    },
  },
  watch: {
    eventMessage(value) {
      if (value && value.indexOf("success") >= 0) {
        this.formData.name = "";
        this.formData.email = "";
        this.formData.phone = "";
        this.formData.message = "";
      }
    },
  },
};
</script>